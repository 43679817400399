import React from "react";
import { PrismicRichText } from "@prismicio/react";

// Components
import { Product } from "./single-product";
import { formatPrice } from "../utils/format-price";

export const Products = ({ checkout, contactUsText, currentLanguage }) => {
  return (
    <>
      <div className="left-column">
        {checkout.lineItems.map((line_item) => (
          <Product
            key={line_item.id.toString()}
            item={line_item}
            currentLanguage={currentLanguage}
          />
        ))}
      </div>

      <div className="right-column">
        <div className="subtotal-container">
          <p>{currentLanguage === `fr` ? <>Sous-total</> : <>Subtotal</>}</p>

          <p>
            {formatPrice(
              checkout.subtotalPriceV2.currencyCode,
              checkout.subtotalPriceV2.amount
            )}
          </p>
        </div>

        <p>
          {currentLanguage === `fr` ? (
            <>Frais de livraisons calculés à l’étape suivante</>
          ) : (
            <>Shipping costs calculated at next step</>
          )}
        </p>

        <div className="checkout-link-container">
          {currentLanguage === `fr` ? (
            <a href={checkout.webUrl} className="uppercase">
              Passer la commande
            </a>
          ) : (
            <a href={checkout.webUrl} className="uppercase">
              Proceed To Checkout
            </a>
          )}
        </div>

        <div className="contact-us-text">
          <PrismicRichText field={contactUsText.richText} />
        </div>
      </div>
    </>
  );
};
