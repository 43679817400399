import React from "react";
import { Link } from "gatsby";

export const Empty = ({ language }) => {
  return (
    <div className="cart-empty-container">
      <p>
        {language === `en`
          ? `You have no items in your bag.`
          : `Vous n’avez pas d’articles dans votre panier`}
      </p>
      <Link to={`/${language}/collections/`}>
        {language === `en`
          ? `View our latest collections.`
          : `Voir nos dernières collections`}
      </Link>
    </div>
  );
};
