import React, { useContext, useState, useCallback, useEffect } from "react";
import { Link } from "gatsby";
import debounce from "lodash.debounce";
import { useAsyncFn } from "react-use";

// Context
import { StoreContext } from "../context/store";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { formatPrice } from "../utils/format-price";

export const Product = ({ item, currentLanguage }) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);
  const [quantity, setQuantity] = useState(item.quantity);

  const [productTranslation, getProductTranslation] = useAsyncFn(async (id) => {
    const response = await fetch(
      `/.netlify/functions/get-product-translations`,
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    const result = await response.json();
    return result;
  }, []);

  useEffect(() => {
    if (item !== null) {
      getProductTranslation(item.variant.product.id);
    }
  }, [item]);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback((value) => uli(value), []);

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const imageItem = item.variant.image && (
    <AspectRatioImageContainer image={null} padding={140}>
      <img src={item.variant.image.src} alt={item.variant.image.altText} />
    </AspectRatioImageContainer>
  );

  return (
    <div className="single-product">
      <div className="image-container">{imageItem}</div>
      <div className="text-container">
        <h3 className="product-title">
          <Link
            to={`/${currentLanguage}/products/${item.variant.product.handle}/`}
          >
            {currentLanguage === `fr` ? (
              <>
                {productTranslation.loading === false &&
                  productTranslation.value !== undefined && (
                    <>{productTranslation.value}</>
                  )}
              </>
            ) : (
              <>{item.title}</>
            )}
          </Link>
        </h3>

        <p className="total-price">{subtotal}</p>

        <p className="product-quantity">QTY: {item.quantity}</p>

        <div className="button-container">
          <button
            type="button"
            className="uppercase small-text"
            onClick={() => doIncrement()}
          >
            {currentLanguage === `fr` ? (
              <>Ajouter une exemplaire</>
            ) : (
              <>Add One More</>
            )}
          </button>

          <button
            type="button"
            onClick={() => handleRemove()}
            className="uppercase small-text"
          >
            {currentLanguage === `fr` ? <>Supprimer</> : <>Remove</>}
          </button>
        </div>
      </div>
    </div>
  );
};
