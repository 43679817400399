import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { use100vh } from "react-div-100vh";

// Context
import { StoreContext } from "../../components/context/store";
import { PageColor } from "../../components/context/page-color";
import { HeaderColor } from "../../components/context/header-color";
import { CurrentLanguage } from "../../components/context/current-language";

// SEO
import { PageSeo } from "../../components/global/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { Products } from "../../components/cart/products";
import { Empty } from "../../components/cart/cart-empty";

const Page = styled.div`
  padding: 0 50px;
  min-height: calc(${(props) => props.height}px - 130px);

  & .grid-11 {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-column-gap: 50px;

    @media (max-width: 1445px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 25px;
    }
  }

  & .title-container {
    margin: 0 0 90px 0;

    @media (max-width: 999px) {
      margin: 0 0 50px 0;
    }
  }

  & .left-column {
    grid-column: 1 / 8;

    @media (max-width: 800px) {
      grid-column: 1 / 13;
    }

    & .single-product {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 50px;

      @media (max-width: 1445px) {
        grid-column-gap: 40px;
      }

      @media (max-width: 999px) {
        grid-column-gap: 25px;
      }

      border-top: 1px solid #09184f;

      padding: 25px 0;

      & .image-container {
        grid-column: 1 / 3;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .text-container {
        grid-column: 3 / 7;

        & h3 {
          @media (max-width: 767px) {
            font-size: 19px;
            line-height: 26px;
          }
        }

        & .total-price {
          margin: 0;
        }

        & .product-quantity {
          margin: 25px 0;
        }

        & button {
          font-size: 12px;
          line-height: 24px;
        }

        & .button-container {
          & button {
            display: block;
          }
        }
      }
    }
  }

  & .right-column {
    grid-column: 8 / 13;

    @media (max-width: 800px) {
      grid-column: 1 / 13;
    }

    border-top: 1px solid #09184f;

    & p {
      margin: 0;
    }

    & .subtotal-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      margin: 25px 0 0 0;

      & p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    & .checkout-link-container {
      margin: 30px 0 90px 0;

      & a {
        display: block;
        width: 100%;

        font-size: 12px;
        line-height: 50px;

        text-align: center;

        background-color: #09184f;
        color: #fff;
      }
    }
  }

  & .cart-empty-container {
    grid-column: 1 / 12;
  }

  @media (max-width: 1445px) {
    padding: 0 40px;
  }

  @media (max-width: 999px) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    padding: 0 25px;

    &.page {
      margin: 160px 0 0 0;
    }
  }
`;

const Cart = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const { checkout, loading } = useContext(StoreContext);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  const height = use100vh();

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  return (
    <>
      <PageSeo
        title={data.prismicCart.data.title.text}
        image={null}
        description={null}
        url={data.prismicCart.url}
      />

      <Page className="page" height={height}>
        <div className="title-container">
          <h1>Shopping Bag</h1>
        </div>

        <div className="grid-11">
          {checkout.lineItems.length !== 0 ? (
            <Products
              checkout={checkout}
              contactUsText={data.prismicCart.data.contact_us_text}
              currentLanguage={currentLanguage}
            />
          ) : (
            <Empty language={`en`} />
          )}
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicCart(lang: { eq: "en-gb" }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          text
        }
        contact_us_text {
          richText
        }
      }
    }
  }
`;

export default withPrismicPreview(Cart);
